
import { onMounted, ref } from "vue"
import CompaniesService from "@/services/CompaniesService"
import PrimaryButton from "../buttons/PrimaryButton.vue"

export default {
  name: "CompanyForm",
  props: {
    company: {
      type: Object,
      default: null,
    },
  },
  emits: ["reload"],
  setup(props: any, { emit }: any) {
    const companiesService = new CompaniesService()

    const form = ref({
      name: props.company ? props.company.name : "",
      code: props.company ? props.company.code : "",
    })

    function saveCompany() {
      if (form.value.code) {
        if (props.company) {
          companiesService.editCompany(props.company.id, form.value).then((res: any) => {
            emit("reload")
          })
        } else {
          companiesService.createCompany(form.value).then((res: any) => {
            emit("reload")
          })
        }
      }
    }
    return {
      form,
      saveCompany,
    }
  },
  components: { PrimaryButton },
}
