
import { reactive, ref, defineComponent } from "vue"
import CompaniesService from "@/services/CompaniesService"
import CompanyForm from "./CompanyForm.vue"

export default defineComponent({
  components: { CompanyForm },
  props: {},
  setup(props) {
    const companiesService = new CompaniesService()
    const companies = reactive([])
    const scrollable = ref(true)
    const deleteCompanyDialogVisible = ref(false)
    const companyFormDialogVisible = ref(false)
    const toBeDeletedCompany = ref(null)
    const editedCompany = ref()

    loadCompanies()

    function loadCompanies() {
      companiesService.getCompanies().then((_companies: any) => {
        ;[].push.apply(companies, _companies.data)
      })
    }

    function createCompany() {
      editedCompany.value = null
      companyFormDialogVisible.value = true
    }

    function editCompany(company: any) {
      editedCompany.value = company
      companyFormDialogVisible.value = true
    }

    function confirmDeleteCompany() {
      companiesService.deleteCompany(toBeDeletedCompany.value.id).then(() => {
        toBeDeletedCompany.value = null
        reload()
      })
    }

    function showDeleteCompanyDialog(data: any) {
      deleteCompanyDialogVisible.value = true
      toBeDeletedCompany.value = data
    }

    function reload() {
      companies.length = 0
      deleteCompanyDialogVisible.value = false
      companyFormDialogVisible.value = false
      loadCompanies()
    }

    return {
      scrollable,
      companies,
      createCompany,
      confirmDeleteCompany,
      deleteCompanyDialogVisible,
      toBeDeletedCompany,
      showDeleteCompanyDialog,
      companyFormDialogVisible,
      editedCompany,
      reload,
      editCompany,
    }
  },
})
